import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SManagerEduMoralStuDataModule extends HTTP {
    addProcessingRecords(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ADD_PROCESSING_RECORDS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    restoreDefaultSetting(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.RESTORE_DEFAULT_SETTING,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    deleteProcessingRecords(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_PROCESSING_RECORDS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    processingRecordListQuery(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.PROCESSING_RECORD_LIST_QUERY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getMoralDisposeTypeList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.MORAL_DISPOSE_TYPE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { SManagerEduMoralStuDataModule };

import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js'

class OverallResults extends HTTP {
	getListData(data) {
		return new Promise((resolve, reject) => {
			this.axiosPost({
				url: config.API.GET_SCHOOL_ZHENG_LIST,
				data: data,
				header: 'application/json',
				success(data) {
					resolve({
						data,
						status: 0
					});
				},
				error(error) {
					resolve({
						error,
						status: - 1
					})
				}
			});
		});
	}

	getCount(id) {
		return new Promise((resolve, reject) => {
			this.axiosGet({
				url: config.API.GET_SCHOOL_ZHENG_XQ + id,
				header: 'application/json',
				success(data) {
					resolve({
						data,
						status: 0
					});
				},
				error(error) {
					resolve({
						error,
						status: - 1
					})
				}
			});
		});
	}
}

export { OverallResults }

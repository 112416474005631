import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SManagerEduDeormitoryGradeDataModel extends HTTP {
    getSchoolOrganizationTree (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.Grade_CLASS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getGradeDateRuleList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_GRADEDATE_DATE_RULE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getStuQuantizationDetail (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_STU_QUANTIZATION_DETAIL_BY_GRADE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { SManagerEduDeormitoryGradeDataModel };
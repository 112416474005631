import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SManagerBaseClassroomModule extends HTTP {
    getClassroomList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CLASSROOM_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    schoolBuildingRoomDel(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.DELETE_SCHOOL_BUILDING_ROOM,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getConditionList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CONDITIONS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getBuildingList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_BUILDING_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getBuildingFloorList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_BUILDING_FLOOR_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    classroomSave(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.CLASSROOM_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    importExcel(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.IMPORT_EXCEL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
	
	// 楼栋管理接口
	// 楼栋管理分页列表
	getFloorList(data) {
	    return new Promise((resolve, reject) => {
	        this.axiosPost({
	            url: config.API.GET_FLOOR_LIST,
	            data: data,
	            header: 'application/json',
	            success(data) {
	                resolve({
	                    data,
	                    status: 0
	                });
	            },
	            error(error) {
	                resolve({
	                    error,
	                    status: -1
	                })
	            }
	        });
	    });
	}
	
	// 楼栋管理列表、批量删除
	schoolFloorRoomDel(data) {
	    return new Promise((resolve, reject) => {
	        this.axiosPostArray({
	            url: config.API.DELETE_SCHOOL_FLOOR_ROOM,
	            data: data,
	            header: 'application/json',
	            success(data) {
	                resolve({
	                    data,
	                    status: 0
	                });
	            },
	            error(error) {
	                resolve({
	                    error,
	                    status: -1
	                })
	            }
	        });
	    });
	}
	
	// 楼栋管理导入楼栋
	importExcelFloor(data) {
	    return new Promise((resolve, reject) => {
	        this.axiosFile({
	            url: config.API.IMPORT_EXCEL_FLOOR,
	            data: data,
	            header: 'application/json',
	            success(data) {
	                resolve({
	                    data,
	                    status: 0
	                });
	            },
	            error(error) {
	                resolve({
	                    error,
	                    status: -1
	                })
	            }
	        });
	    });
	}
	
	// 楼栋管理新增、编辑
	floorSave(data) {
	    return new Promise((resolve, reject) => {
	        this.axiosPost({
	            url: config.API.FLOOR_SAVE,
	            data: data,
	            header: 'application/json',
	            success(data) {
	                resolve({
	                    data,
	                    status: 0
	                });
	            },
	            error(error) {
	                resolve({
	                    error,
	                    status: -1
	                })
	            }
	        });
	    });
	}
}

export { SManagerBaseClassroomModule };

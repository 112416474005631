import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SManagerEduDeormitoryDataModule extends HTTP {
    getRoomMoralList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ROOM_MORAL_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAllDormitoryRoomList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ALL_DORMOTORY_ROOM_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    dormitoryRoomRule(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DORMOTORY_ROOM_RULE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    dormitoryAttendanceGetRoomInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DORMOTORY_ATTENDANCE_GET_ROOM_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    
    saveDormitoryRoomDetailInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_DORMOTORY_ROOM_DETAIL_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    updateDormitoryRoomDetailInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.UPDATE_DORMOTORY_ROOM_DETAIL_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    saveDormitoryMoralRule(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_DORMOTORY_MORAL_RULE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    deleteDormitoryMoralRule(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_DORMOTORY_MORAL_RULE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getDormitoryMoralReport(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DORMOTORY_MORAL_REPORT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    exportDormitoryMoralReport(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EXPORT_DORMOTORY_MORAL_REPORT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getLevelStudents(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_LEVEL_STUDENTS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { SManagerEduDeormitoryDataModule };

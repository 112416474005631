import {HTTP} from '@/libs/https.js';
import config from '@/libs/config.js';
class SManagerHeadSetting extends HTTP {
    getDirectorsList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDUCATION_BUREAU_DIRECTORS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    saveDirectors(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDUCATION_BUREAU_DIRECTORS_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    saveReceiverDirectors(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDUCATION_BUREAU_DIRECTORS_SAVE_RECEIVER,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }



}
export {SManagerHeadSetting}

import { HTTP } from "@/libs/https.js"
import config from "@/libs/config.js"

class MeetingListModel extends HTTP {
    delByIds(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: "/api//school/schoolMeetingAnalysis/delByIds",
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            })
        })
    }
}

export { MeetingListModel }
